import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

const initialState = {
  showNavIndicator: false,
};

export const GlobalNavStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initialState),
  withMethods((store) => ({
    enableNavIndicator() {
      patchState(store, { showNavIndicator: true });
    },
    disableNavIndicator() {
      patchState(store, { showNavIndicator: false });
    },
  }))
);
