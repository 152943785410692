import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  ItemOp,
  Orders,
  PagingData,
  ShopingCart,
  ShopingCartBackEnd,
} from '../models';
import { AmmShoppingCartBackEnd } from '../models/catalog.model';
import { ShoppingCartService } from '../services';
import { Favorite } from './../models/favorite.interface';

type ShoppingInfo = {
  favorites: Favorite[];
  orders: Orders[];
  shoppingCarts: ShopingCart[];
};

const initialState: ShoppingInfo = {
  favorites: [],
  orders: [],
  shoppingCarts: [],
};
export const ShoppingInfoStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initialState),
  withComputed((shoppingInfo) => ({
    shoppingCartCount: computed(() => {
      const carts = shoppingInfo.shoppingCarts();
      if (carts.length > 0) {
        return carts
          .filter((x) => x?.id > 0 || (x.code?.length || 0) > 0)
          .map((x) => x.qty)
          .reduce((prev, cur) => {
            return prev + cur;
          }, 0);
      }
      return 0;
    }),
    favoriteCount: computed(() => shoppingInfo.favorites().length),
  })),
  withMethods((store, shoppingService = inject(ShoppingCartService)) => ({
    loadAllFavorites() {
      //TODO: here we need merge current favorites in memory and the API response
    },
    increaseFavorite(id: number) {
      const existFavorite = store.favorites().findIndex((x) => x.id == id);
      if (existFavorite == -1) {
        patchState(store, { favorites: [...store.favorites(), { id: id }] });
      }
    },
    decreaseFavorite(id: number) {
      patchState(store, (state) => ({
        favorites: state.favorites.filter((x) => x.id !== id),
      }));
    },
    increaseShopingCart(id: number, merchCode: string) {
      const index = store
        .shoppingCarts()
        .findIndex((x) => x.id == id || x.code === merchCode);
      if (index == -1) {
        patchState(store, {
          shoppingCarts: [
            ...store.shoppingCarts(),
            { id: id, qty: 1, code: merchCode },
          ],
        });
      } else {
        patchState(store, (state) => ({
          shoppingCarts: state.shoppingCarts.map((x, idx) =>
            idx == index ? { ...x, qty: x.qty + 1 } : x,
          ),
        }));
      }
    },
    decreaseShopingCart(id: number) {
      const index = store.shoppingCarts().findIndex((x) => (x.id = id));
      if (index != -1) {
        patchState(store, (state) => ({
          shoppingCarts: state.shoppingCarts.map((x, idx) =>
            idx == index ? { ...x, qty: Math.max(x.qty - 1, 0) } : x,
          ),
        }));
        this.removeZeroQtyFromCart();
      }
    },
    setVariantShoppingCart(id: number, qty: number, merchCode: string) {
      const index = store
        .shoppingCarts()
        .findIndex((x) => x.id == id || x.code === merchCode);
      if (index == -1) {
        patchState(store, {
          shoppingCarts: [
            ...store.shoppingCarts(),
            { id: id, qty: qty, code: merchCode },
          ],
        });
      } else {
        patchState(store, {
          shoppingCarts: store
            .shoppingCarts()
            .map((sc, idx) => (idx == index ? { ...sc, qty: qty } : sc)),
        });
      }
    },
    removeZeroQtyFromCart() {
      patchState(store, {
        shoppingCarts: store.shoppingCarts().filter((x) => x.qty > 0),
      });
    },
    saveShoppingCartsToDB() {
      const carts = store.shoppingCarts();
      const cartsToDb = <ShopingCartBackEnd>{};

      cartsToDb.itemOps = carts.map(
        (x) =>
          <ItemOp>{
            merchCode: x.code,
            qty: x.qty,
          },
      );

      shoppingService.updateShoppingCart(cartsToDb);
    },
    loadShoppingCarts() {
      shoppingService
        .getShoppingCart()
        .subscribe((response: PagingData<AmmShoppingCartBackEnd>) => {
          if (response && response.dataList) {
            const itemsInDb = response?.dataList[0]?.items?.map(
              (x) =>
                <ShopingCart>{
                  code: x?.merchCode,
                  qty: x?.qty,
                },
            );

            itemsInDb?.forEach((x) => {
              this.setVariantShoppingCart(x.id, x.qty, x.code || '');
            });
          }
        });
    },
  })),
);
