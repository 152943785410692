import { inject } from '@angular/core';
import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';
import { tap } from 'rxjs';
import { PagingData } from '../models';
import { Product } from '../models/catalog.model';
import { MansoryService, ProductService } from '../services';

export interface ProductInitState {
  isLoading: boolean;
  hasLoadingError: boolean;
  products: Product[];
  variantSelection: Map<string, string>;
  hasReachBotom: boolean;
}
export const initState: ProductInitState = {
  isLoading: true,
  hasLoadingError: false,
  products: [],
  variantSelection: new Map<string, string>(),
  hasReachBotom: false,
};

export const ProductStore = signalStore(
  {
    providedIn: 'root',
  },
  withState(initState),
  withMethods(
    (
      state,
      productSrv = inject(ProductService),
    ) => ({
      setIsLoading() {
        patchState(state, { isLoading: true, hasLoadingError: false });
      },

      loadSuccess() {
        patchState(state, { isLoading: false, hasLoadingError: false });
      },
      loadFailed() {
        patchState(state, { isLoading: false, hasLoadingError: true });
      },
      loadProducts(pageIndex: number, pageSize: number = 32) {
        productSrv
          .getAllProducts(pageIndex, pageSize)
          .pipe(tap(() => patchState(state, { isLoading: true })))
          .subscribe(
            (response: PagingData<Product>) => {
              if (
                response &&
                response.dataList &&
                response.dataList.length > 1
              ) {
                patchState(state, {
                  isLoading: false,
                  products: response.dataList,
                });
              } else {
                patchState(state, {
                  isLoading: false,
                });
              }
            },
            (err) => {
              console.log(err);
              patchState(state, {
                isLoading: false,
                hasLoadingError: true,
                products: [],
              });
            },
          );
      },
      viewMoreProducts(pageIndex: number, pageSize: number = 32) {
        productSrv
          .getAllProducts(pageIndex, pageSize)
          .pipe(tap(() => patchState(state, { isLoading: true })))
          .subscribe(
            (response: PagingData<Product>) => {
              if (
                response &&
                response.dataList &&
                response.dataList.length > 1
              ) {
                patchState(state, {
                  isLoading: false,
                  products: state.products().concat(response.dataList),
                });
              } else {
                patchState(state, {
                  isLoading: false,
                  hasReachBotom: true,
                });
              }
            },
            (err) => {
              console.log(err);
              patchState(state, {
                isLoading: false,
                hasLoadingError: true,
                products: [],
              });
            },
          );
      },
      setVariantSelection(type: string, value: string) {
        patchState(state, {
          variantSelection: state.variantSelection().set(type, value),
        });
      },
    }),
  ),
);
